import AuthComponentBase from '@/shared/application/auth-component-base';
import { Component, Watch, Prop } from 'vue-property-decorator';
import LanguageStore from '../../../stores/language-store';
import { DateTime } from 'luxon';

@Component
export default class DateRangeSelectionComponent extends AuthComponentBase {
    @Prop({ default: false }) readonly show: boolean;
    @Prop({ default: true }) readonly oneLine: boolean;
    selectedLanguage: ILanguageDto = null;

    startDate: string = DateTime.local().minus({ days: 1 }).toFormat('yyyy-MM-dd');
    endDate: string = DateTime.local().toFormat('yyyy-MM-dd');

    showStart: boolean = false;
    showEnd: boolean = false;

    created() {
        this.selectedLanguage = LanguageStore.getLanguage();
    }


    mounted() {
        this.onDateChange();
    }

    get startDateFormatted() {
        return this.selectFormat(this.startDate);
    }

    get endDateFormatted() {
        return this.selectFormat(this.endDate);
    }

    @Watch('show', { immediate: true })
    onShowChanged() {
        if (this.show) {           
            this.startDate = this.formatDate(new Date());
            this.endDate = this.formatDate(this.addDays(new Date(), 1));

            this.$emit('date-change', this.startDate, this.endDate);
        }        
    }

    addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    parseDate(date) {
        if (!date) return null;

        if (this.selectedLanguage.languageCode === 'en') {
            const [year, month, day] = date.split('-');
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        }
        const [day, month, year] = date.split('-');
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    }

    formatDate(date: Date) {
        return DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
    }

    selectFormat(date: string) {
        const [year, month, day] = date.split('-');
        return this.selectedLanguage.languageCode === 'en' ? `${year}-${month}-${day}` : `${day}-${month}-${year}`;
    }

    onDateChange() {
        this.$emit('date-change', this.startDate, this.endDate);
    }
}